/**
 * Styled-components theme for Reassure-UI navigation components
 */

import { lighten } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { white, black, lightBlue } = pallette;

const themeConfig = {
    colors: {
        link: {
            text: black,
            hover: {
                text: lighten(0.1, black),
            },
        },
        tabs: {
            text: white,
            background: lightBlue,
        },
    },
};

export default themeConfig;
