/**
 * Styled-components theme for Reassure-UI animation and loading components
 */

import { lighten, rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { white, brightBlue, darkBlue } = pallette;

const themeConfig = {
    colors: {
        loadingDots: {
            dotColor: white,
        },
        skeleton: {
            background: 'hsla(230, 20%, 84%, 0.4)',
            contrast: {
                background: 'hsla(230, 20%, 84%, 0.4)',
            },
        },
        progressIndicator: {
            bar: lighten(0.3, darkBlue),
            track: rgba(brightBlue, 0.6),
            value: darkBlue,
            label: darkBlue,
        },
    },
};

export default themeConfig;
