import { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousRouteContext = createContext(undefined);

export const PreviousRouteContextProvider = ({ children }) => {
    const location = useLocation();
    const ref = useRef(undefined);
    const [previousRoute, setPreviousRoute] = useState();

    useEffect(() => {
        setPreviousRoute(ref.current);
        ref.current = location.pathname;
    }, [location.pathname]);

    return <PreviousRouteContext.Provider value={previousRoute}>{children}</PreviousRouteContext.Provider>;
};

export const usePreviousRoute = () => useContext(PreviousRouteContext);
