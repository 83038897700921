/**
 * Styled-components theme for Reassure-UI document components
 */

import { rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { white, paleGrey } = pallette;

const themeConfig = {
    colors: {
        pdfViewer: {
            background: paleGrey,
            toolbar: {
                text: white,
                background: 'hsla(228, 24%, 45%, 0.97)',
                icons: rgba(white, 0.8),
                separators: rgba(white, 0.2),
            },
        },
    },
};

export default themeConfig;
