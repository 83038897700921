import { AppProvider } from '@reassure/ui/core';
import React from 'react';
import { useTheme } from '../../store/appearance/hooks';

const DynamicAppProvider = ({ children }) => {
    const { theme, customCss } = useTheme();

    return (
        <AppProvider theme={theme} head={{ customCss }}>
            {children}
        </AppProvider>
    );
};

export default DynamicAppProvider;
