import { useEffect, useState } from 'react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useApp } from '../../store/app/hooks';
import oktaAuthService from './auth';
import { useHistory } from 'react-router-dom';

export const useOktaConfig = () => {
    const { config } = useApp();
    const [oktaConfig, setOktaConfig] = useState();
    const history = useHistory();

    useEffect(() => {
        if (!!config && !oktaConfig) {
            setOktaConfig({
                oktaAuth: oktaAuthService.configure(config, history),
                onAuthRequired: () => {
                    history.push('/log-in');
                },
                restoreOriginalUri: async (_oktaAuth, originalUri) => {
                    history.replace(toRelativeUrl(originalUri, window.location.origin));
                },
            });
        }
    }, [config, oktaConfig, setOktaConfig, history]);

    return oktaConfig;
};
