/**
 * Styled-components theme for Reassure-UI button components
 */

import { lighten, rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { transparent, white, lightBlue } = pallette;

const themeConfig = {
    colors: {
        button: {
            primary: {
                text: white,
                background: lightBlue,
                border: lightBlue,
                hover: {
                    text: white,
                    background: lighten(0.05, lightBlue),
                    border: lighten(0.05, lightBlue),
                },
                disabled: {
                    text: rgba(white, 0.5),
                    background: lighten(0.35, lightBlue),
                    border: lighten(0.35, lightBlue),
                },
            },
            secondary: {
                text: lightBlue,
                background: transparent,
                border: lightBlue,
                hover: {
                    text: lighten(0.2, lightBlue),
                    background: transparent,
                    border: lighten(0.2, lightBlue),
                },
                disabled: {
                    text: lighten(0.35, lightBlue),
                    background: transparent,
                    border: lighten(0.35, lightBlue),
                },
            },
        },
    },
};

export default themeConfig;
