import { OktaAuth } from '@okta/okta-auth-js';

/**
 * A helper service for using the @okta/okta-auth-js package
 * @type {Object}
 */

const oktaAuthService = {
    client: null,
};

/**
 * Get the config for putting an OktaAuth client
 * @return {[type]} [description]
 */
oktaAuthService.getConfig = (config, history) => {
    const { OKTA_CLIENT_ID, OKTA_ORIGIN } = config;
    return {
        history,
        issuer: `${OKTA_ORIGIN}/oauth2/default`,
        clientId: OKTA_CLIENT_ID,
        redirectUri: `${window.location.origin}/implicit/callback`,
        useClassicEngine: true,
        postLogoutRedirectUri: `${window.location.origin}/log-in`,
        scopes: ['openid', 'groups', 'rcpMeta'],
        tokenManager: {
            storage: 'sessionStorage',
        },
    };
};

/**
 * Configure the auth service
 */
oktaAuthService.configure = (config, history) => {
    // Setup of the configuration for the OKTA Security component

    const oktaAuth = new OktaAuth(oktaAuthService.getConfig(config, history));

    oktaAuthService.client = oktaAuth;

    return oktaAuth;
};

/**
 * Gets the accessToken string if one exists in the Okta Auth tokenManager
 */
oktaAuthService.getAccessToken = async () => {
    const token = await oktaAuthService.client.tokenManager.get('accessToken');

    return token ? token.accessToken : null;
};

/**
 * Get the current session for a logged in user form OKTA
 */
oktaAuthService.getSession = async () => {
    try {
        const session = await oktaAuthService.client.session.get();
        return session;
    } catch (error) {
        throw error;
    }
};

export default oktaAuthService;
