/**
 * Styled-components theme for Reassure-UI modal components
 */

import { lighten } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { white, black } = pallette;

const themeConfig = {
    colors: {
        modals: {
            text: black,
            background: white,
            separators: 'rgba(0,0,0,0.2)',
            overlay: 'rgba(0,0,0,0.4)',
            link: {
                text: '#007d39',
                hover: {
                    text: lighten(0.1, '#007d39'),
                },
            },
        },
    },
};

export default themeConfig;
