import React from 'react';
import { Helmet } from 'react-helmet';
import { useCookiePro } from './hooks';

export const CookiePro = () => {
    const cookieId = useCookiePro();
    return (
       cookieId && <Helmet>
            <script
                data-testid="cookie-pro-autoblock"
                type="text/javascript"
                src={`https://cdn-ukwest.onetrust.com/consent/${cookieId}/OtAutoBlock.js`}>
            </script>
            <script
                data-testid="cookie-pro-scripttemplates"
                src={`https://cdn-ukwest.onetrust.com/consent/${cookieId}/otSDKStub.js`}
                type="text/javascript"
                charset="UTF-8"
                data-domain-script={cookieId}
            ></script>
        </Helmet>
    );
};
