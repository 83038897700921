import semver from 'semver';
import getServerStatusRequest from '../../services/http/getServerStatusRequest';
import { APP_UPDATE, APP_UPDATE_REQUESTED } from './types';
import { STORE_STATUS_MAINTENANCE, STORE_STATUS_OK } from '../statuses';
import { graphQLQuery } from '../../graphql/client';
import { configQuery } from '../../graphql/queries/config';
import { isInActiveMaintenanceWindow } from './helpers';

export const getAppConfigAction = async dispatch => {
    const action = {
        type: APP_UPDATE,
    };

    try {
        const payload = {
            status: STORE_STATUS_OK,
        };

        const config = await graphQLQuery(configQuery);

        const maintenanceWindow = {
            from: config.PLANNED_MAINTENANCE_FROM || null,
            to: config.PLANNED_MAINTENANCE_TO || null,
        };

        payload.maintenanceWindow = maintenanceWindow;
        payload.config = config;

        // check if there is an active maintenance window
        const activeWindow = isInActiveMaintenanceWindow(maintenanceWindow);

        if (activeWindow) {
            payload.status = STORE_STATUS_MAINTENANCE;
        }

        return dispatch({ ...action, payload });
    } catch (error) {
        return dispatch({
            ...action,
            payload: {
                status: STORE_STATUS_MAINTENANCE,
            },
        });
    }
};

export const getServerStatusAction = async dispatch => {
    dispatch({
        type: APP_UPDATE_REQUESTED,
    });

    const action = {
        type: APP_UPDATE,
    };

    try {
        const serverStatus = await getServerStatusRequest();

        // check the app version is valid
        if (
            !serverStatus ||
            !serverStatus.hasOwnProperty('status') ||
            !serverStatus.hasOwnProperty('version') ||
            !serverStatus.hasOwnProperty('maintenanceWindow')
        ) {
            throw Error('Invalid status received form server');
        }

        const payload = {
            status: STORE_STATUS_OK,
            version: serverStatus.version,
            maintenanceWindow: serverStatus.maintenanceWindow,
        };

        // check if there is an active maintenance window
        const activeWindow = isInActiveMaintenanceWindow(serverStatus.maintenanceWindow);

        if (activeWindow) {
            payload.status = STORE_STATUS_MAINTENANCE;
            return dispatch({ ...action, payload });
        }

        const versionDiff = semver.diff(serverStatus.version, process.env.VITE_RCP_VERSION);
        const invalidDiffs = ['major', 'premajor', 'minor', 'preminor'];
        const versionNotValid = versionDiff && invalidDiffs.indexOf(versionDiff) !== -1;

        if (versionNotValid) {
            console.warn(`There is a ${versionDiff} version mismatch between the web app and the server`);
            payload.status = STORE_STATUS_MAINTENANCE;
            return dispatch({ ...action, payload });
        }

        return dispatch({ ...action, payload });
    } catch (error) {
        return dispatch({
            ...action,
            payload: {
                status: STORE_STATUS_MAINTENANCE,
            },
        });
    }
};
