/**
 * Styled-components theme for Reassure-UI button components
 */

import { darken, lighten } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { transparent, black, midGreen } = pallette;

const themeConfig = {
    colors: {
        button: {
            primary: {
                text: black,
                background: midGreen,
                border: midGreen,
                hover: {
                    text: black,
                    background: lighten(0.04, midGreen),
                    border: lighten(0.04, midGreen),
                },
                disabled: {
                    text: black,
                    background: darken(0.3, midGreen),
                    border: darken(0.3, midGreen),
                },
            },
            secondary: {
                text: midGreen,
                background: transparent,
                border: midGreen,
                hover: {
                    text: midGreen,
                    background: transparent,
                    border: lighten(0.05, midGreen),
                },
                disabled: {
                    text: darken(0.2, midGreen),
                    background: transparent,
                    border: darken(0.2, midGreen),
                },
            },
        },
    },
};

export default themeConfig;
