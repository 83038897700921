import { Security } from '@okta/okta-react';
import { LoadingDots, breakpointDown, breakpointUp } from '@reassure/ui/core';
import { styled } from '@reassure/ui/styled-components';
import { conditionalThemeStyle } from '../../services/utils/themeHelpers';

const StyledSecurity = styled(Security)`
    height: 100%;
`;

export { StyledSecurity as Security };

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${breakpointDown.lg`
    ${props =>
        props.mobileMenuOpen
            ? `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    `
            : ``}
    `}
`;

export const AppContent = styled.div`
    flex: 1 0 auto;
    padding-bottom: 20px;
    background-color: ${({ theme, backgroundColor }) =>
        theme.colors.body[backgroundColor] || theme.colors.body.background};

    ${breakpointUp.lg`
    padding-bottom: 40px;
    `}

    a {
        color: ${({ theme }) => theme.colors.link.text};

        &:active,
        &:visited {
            color: ${({ theme }) => theme.colors.link.text};
        }

        &:hover {
            color: ${({ theme }) => theme.colors.link.hover.text};
        }
    }

    .rui-table {
        & > tbody {
            font-weight: ${({ theme }) =>
                conditionalThemeStyle(theme, { light: theme.fontWeights.regular, default: 'inherit' })};
        }
    }
`;

const StyledLoadingDots = styled(LoadingDots).attrs({
    role: 'alert',
    'aria-busy': true,
})`
    margin-top: ${props => props.theme.space[8]};
`;

export { StyledLoadingDots as LoadingDots };
