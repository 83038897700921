/**
 * Styled-components theme for Reassure-UI help components
 */

import { rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { transparent, white, darkBlue } = pallette;

const themeConfig = {
    colors: {
        tooltip: {
            text: white,
            background: rgba(darkBlue, 0.95),
            inverted: {
                text: darkBlue,
                background: rgba(white, 0.95),
            },
        },
        tooltipToggle: {
            text: 'currentColor',
            background: transparent,
            border: 'currentColor',
        },
    },
};

export default themeConfig;
