/**
 * Styled-components theme for Reassure-UI form components
 */
import { darken } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { transparent, white, black, lightGrey, paleBlue, lightBlue, darkBlue, midGreen, lightPink, midRed } = pallette;

const themeConfig = {
    colors: {
        formField: {
            focusHighlight: paleBlue,
        },
        cardRadioGroup: {
            text: darkBlue,
            background: transparent,
            border: lightBlue,
            radio: white,
            hover: {
                text: darkBlue,
                background: lightGrey,
                border: darken(0.1, lightBlue),
                radio: white,
            },
            checked: {
                text: white,
                background: lightBlue,
                border: lightBlue,
                radio: midGreen,
            },
        },
        dropdown: {
            text: black,
            background: white,
            border: darkBlue,
            caret: lightBlue,
            focus: {
                background: white,
                border: darkBlue,
                error: {
                    background: white,
                    border: midRed,
                },
            },
            error: {
                background: white,
                border: midRed,
            },
        },
        dropdownMenu: {
            text: darkBlue,
            background: transparent,
            border: lightBlue,
            caret: lightBlue,
            menu: {
                background: white,
            },
            menuItem: {
                text: darkBlue,
                hover: {
                    text: white,
                    background: lightBlue,
                },
            },
        },
        input: {
            text: {
                text: black,
                background: white,
                border: darkBlue,
                focus: {
                    background: white,
                    border: darkBlue,
                    error: {
                        background: lightPink,
                        border: midRed,
                    },
                },
                error: {
                    background: lightPink,
                    border: midRed,
                },
            },
            checkbox: {
                background: white,
                border: darkBlue,
                checked: {
                    background: transparent,
                    border: lightBlue,
                    tickIcon: lightBlue,
                },
            },
        },
        label: {
            text: darkBlue,
        },
        select: {
            text: black,
            background: white,
            border: darkBlue,
            caret: lightBlue,
            focus: {
                background: white,
                border: darkBlue,
                error: {
                    background: white,
                    border: midRed,
                },
            },
            error: {
                background: white,
                border: midRed,
            },
        },
        toggleSwitch: {
            checked: {
                text: white,
                background: lightBlue,
            },
            unchecked: {
                text: lightBlue,
                background: 'hsl(230, 20%, 84%)',
            },
            switch: white,
            border: lightBlue,
        },
        slider: {
            marks: darkBlue,
            track: darkBlue,
            dots: {
                border: darkBlue,
                background: white,
            },
            handle: {
                background: lightBlue,
                bars: white,
            },
        },
    },
};

export default themeConfig;
