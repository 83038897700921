import axios from 'axios';
import { ClientJS } from 'clientjs';
import { store } from '../../store';
import oktaAuthService from '../okta/auth';

const httpRequestService = {
    protocols: {
        http: `${process.env.VITE_SSL === 'false' ? 'http' : 'https'}`,
        ws: `${process.env.VITE_SSL === 'false' ? 'ws' : 'wss'}`,
    },
};

httpRequestService.endpoints = {
    graphql: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/graphql`,
    status: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/status`,
    documents: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/documents`,
    factsheets: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/factsheets`,
    webSocket: `${httpRequestService.protocols.ws}://${process.env.VITE_SERVER_HOSTNAME}/subscriptions`,
};

httpRequestService.resetProperties = () => {
    httpRequestService.protocols = {
        http: `${process.env.VITE_SSL === 'false' ? 'http' : 'https'}`,
        ws: `${process.env.VITE_SSL === 'false' ? 'ws' : 'wss'}`,
    };

    httpRequestService.endpoints = {
        graphql: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/graphql`,
        status: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/status`,
        documents: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/documents`,
        factsheets: `${httpRequestService.protocols.http}://${process.env.VITE_SERVER_HOSTNAME}/factsheets`,
        webSocket: `${httpRequestService.protocols.ws}://${process.env.VITE_SERVER_HOSTNAME}/subscriptions`,
    };
};

/**
 * Make an http request using an axios request
 * @param {Object} request The axios
 */
httpRequestService.makeRequest = async request => {
    try {
        const response = await axios(request);
        return response.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Get the current session for a logged in user form OKTA
 */
httpRequestService.getAuthorizeHeader = async () => {
    let sessionId = store.getState().session.get('id');
    let bearerToken;

    const basicAuthToken =
        process.env.VITE_BASIC_AUTH_USER !== 'false'
            ? `Basic ${btoa(process.env.VITE_BASIC_AUTH_USER + ':' + process.env.VITE_BASIC_AUTH_PASSWD)}`
            : false;

    if (sessionId) {
        const accessToken = await oktaAuthService.getAccessToken();

        bearerToken = `Bearer ${accessToken}.${sessionId}`;

        return {
            authorization: basicAuthToken ? `${basicAuthToken}, ${bearerToken}` : bearerToken,
        };
    }

    return basicAuthToken ? { authorization: basicAuthToken } : {};
};

/**
 * Get the RCP version header
 */
httpRequestService.getVersionHeader = () => {
    return {
        'x-rcp-version': process.env.VITE_RCP_VERSION,
    };
};

httpRequestService.setClient = () => {
    const client = new ClientJS();
    httpRequestService.client = client;
};

/**
 * Get the RCP digital fingerprint header
 */
httpRequestService.getDigitalFingerprint = () => {
    if (!httpRequestService.client) {
        httpRequestService.setClient();
    }

    const digitalFingerprint = httpRequestService.client.getCustomFingerprint();

    return {
        'x-rcp-digital-fingerprint': digitalFingerprint.toString(),
    };
};

/**
 * Get the RCP content type header
 */
httpRequestService.getContentTypeHeader = ({ contentType }) => {
    return {
        'x-rcp-content-type': contentType,
    };
};

export default httpRequestService;
