import { Map } from 'immutable';
import moment from 'moment';

import { SESSION_INACTIVITY_TIMER_RESET, SESSION_SET_ID, SESSION_RESET, SESSION_RESET_ID } from './types';

const inactivityTimeoutLimit = process.env.VITE_AUTOMATIC_INACTIVITY_TIMEOUT_SECONDS
    ? parseInt(process.env.VITE_AUTOMATIC_INACTIVITY_TIMEOUT_SECONDS, 10)
    : 900;
const getTimeoutTime = () => moment().add(inactivityTimeoutLimit, 'seconds').format();
/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    inactivityTimeoutTime: getTimeoutTime(),
    id: null,
};

const initialState = Map(schema);

/**
 *
 * The Session Reducer
 *
 * A reducer responsible for managing details of a single user session
 *
 */
const sessionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SESSION_RESET:
            return initialState;

        case SESSION_RESET_ID:
            return state.set('id', null);

        case SESSION_INACTIVITY_TIMER_RESET:
            return state.set('inactivityTimeoutTime', getTimeoutTime());

        case SESSION_SET_ID:
            return state.set('id', payload);

        default:
            return state;
    }
};

export { initialState };

export default sessionReducer;
