import { ApolloClient, HttpLink, InMemoryCache, from, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import httpRequestService from '../services/http/request';
import oktaAuthService from '../services/okta/auth';
import { store } from '../store';
import { SESSION_INACTIVITY_TIMER_RESET } from '../store/session/types';
import { authMiddleware, digitalFingerprintMiddleware, maintenanceAfterware, versionMiddleware } from './middleware';

const httpLink = new HttpLink({
    uri: httpRequestService.endpoints.graphql,
    credentials: window.location.origin.indexOf('localhost') !== -1 ? 'include' : 'same-origin',
});

export const wsLink = new WebSocketLink({
    uri: httpRequestService.endpoints.webSocket,
    options: {
        reconnect: true,
        connectionParams: async () => {
            try {
                const sessionId = store.getState().session.get('id');

                if (!sessionId) return {};

                const accessToken = await oktaAuthService.getAccessToken();

                return {
                    authorization: `${accessToken}.${sessionId}`,
                };
            } catch (error) {
                throw error;
            }
        },
    },
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

export const client = new ApolloClient({
    link: from([versionMiddleware, digitalFingerprintMiddleware, authMiddleware, maintenanceAfterware, splitLink]),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
        watchQuery: {
            fetchPolicy: 'cache-first',
        },
    },
});

export const transformResponse = data => Object.values(data)[0];

const defineQueryOptions = (input, options) => (input ? Object.assign({}, options, { variables: { input } }) : options);
const defineMutationVariables = input => (input ? { input } : {});

export const graphQLQuery = async (query, input, options, preventResetInactivityTimer) => {
    if (!preventResetInactivityTimer) {
        store.dispatch({
            type: SESSION_INACTIVITY_TIMER_RESET,
        });
    }

    const queryOptions = defineQueryOptions(input, options);
    const { data } = await client.query({ query, ...queryOptions });
    return transformResponse(data);
};

export const graphQLMutation = async (mutation, input) => {
    store.dispatch({
        type: SESSION_INACTIVITY_TIMER_RESET,
    });

    const variables = defineMutationVariables(input);
    const { data } = await client.mutate({ mutation, variables });
    return transformResponse(data);
};
