/**
 * Styled-components theme for Reassure-UI layout components
 */

import { rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';

const { transparent, white, black, graphite, teal, charcoal } = pallette;

const themeConfig = {
    colors: {
        cookieConsent: {
            text: white,
            background: black,
            border: graphite,
        },
        footer: {
            text: white,
            background: black,
            border: graphite,
        },
        scrollArea: {
            scrollbar: rgba(teal, 0.3),
            hover: {
                scrollbar: teal,
            },
        },
        table: {
            border: black,
            header: {
                text: teal,
                background: black,
            },
            body: {
                text: white,
                background: transparent,
                stripedRow: charcoal,
            },
            footer: {
                text: teal,
                background: black,
            },
        },
        labelDivider: {
            border: black,
            badge: {
                text: white,
                background: black,
                border: black,
            },
        },
    },
};

export default themeConfig;
