/**
 * Styled-components theme for Reassure-UI animation and loading components
 */

import { rgba } from '@reassure/ui/styled-components';
import pallette from './pallette';
const { white, charcoal, black, brightBlue } = pallette;

const themeConfig = {
    colors: {
        loadingDots: {
            dotColor: white,
        },
        skeleton: {
            background: charcoal,
            contrast: {
                background: black,
            },
        },
        progressIndicator: {
            bar: brightBlue,
            track: rgba(white, 0.2),
            value: white,
            label: white,
        },
    },
};

export default themeConfig;
